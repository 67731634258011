import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "file-names-types-and-extensions"
    }}>{`File Names, Types, and Extensions`}</h1>
    <p>{`In text, use title case when referring to file names. When referring to the file type, use all caps
and no period. When referring to file extensions, precede with a period, then lowercase the
extension acronym.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Store the new AboutWorkday.pdf file in the Company folder.`}</li>
      </ul>
    </Do>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`SVG files have the extension .svg.`}</li>
      </ul>
    </Do>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      